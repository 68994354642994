import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from "gatsby-background-image"

const MapBG = ({children}) => {
    const data = useStaticQuery(graphql`
        query {
            mapBG: file(relativePath: { eq: "map-bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <BackgroundImage className="bg-fixed bg-cover" fluid={data.mapBG.childImageSharp.fluid}>
            {children}
        </BackgroundImage>
    )
}

export default MapBG