import * as React from "react"
import { Link } from "gatsby"

const SingleFeature = ({heading, desc, link, linkTo, linkText}) => {
  return (
    <div className="flex-1 flex flex-col space-y-5 justify-between">
        <div className="space-y-3">
            <h6 className="text-black">{heading}</h6>
            <p className="text-feature-desc">
                {desc}
            </p>
        </div>

        {link && <Link to={linkTo} className="link">{linkText}</Link>}
    </div>
  )
}

export default SingleFeature;