import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'

import Button from "./button"

import { StaticImage } from "gatsby-plugin-image"

const PublicData = () => {
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {name: {eq: "overview_content"}}) {
                id
                frontmatter {
                    info {
                        title
                        text
                        list_title
                        includes_list
                    }

                }       
            }
        }
    `)

    const {title, text, list_title, includes_list} = data.markdownRemark.frontmatter.info

    return (
        <div className="bg-ecree flex flex-col items-center justify-center overflow-x-hidden">
            <div className="flex flex-col space-y-10 lg:space-y-0 lg:flex-row lg:space-x-20 w-full lg:container lg:max-w-container pt-12 md:py-12 lg:pt-24 lg:pb-0 relative px-5 sm:px-10 xl:px-0">
                <div className="w-full  lg:max-w-2xl flex-col space-y-10">
                    <h1 className="text-black">{title}</h1>
                    <p className="text-gray">{text}</p>
                    <div className="md:flex md:flex-row md:space-y-0 md:space-x-10">
                        <Button buttonText="Get Started Free" buttonLink="https://petroninja.com" secondary={false} />
                        <div className="mt-5 sm:mt-0 sm:flex items-center"><Button buttonText="Contact us to inquire about our data" buttonLink="/contact" secondary={true} /></div>
                    </div>
                </div>
                
                <div className="hidden sm:flex flex-col space-y-5 pt-5 flex-shrink-0 max-w-lg">
                    <h4 className="text-paragraph text-black">{list_title}</h4>
                    <ul className="custom-list">
                        {includes_list.map((el, i) => <li key={i}>{el}</li>)}
                    </ul>
                </div>
            </div> 

            <div className="hidden md:block w-full lg:ml-16 2xl:-mr-100">
                <StaticImage 
                    src="../images/fast-intuitive-graphic@2x.png" 
                    alt="Fast Intuitive Graphics"
                    layout="fullWidth"
                 />
            </div>
            
            <div className="md:hidden -mx-56">
                    <StaticImage 
                        src="../images/fast-intuitive-graphic-mobile.png" 
                        alt="Fast Intuitive Graphics"
                        layout="fullWidth"
                    />
             </div>
        </div>
    )
}

export default PublicData;