import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import Button from "./button";

const ModernEnergy = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { name: { eq: "overview_content" } }) {
        frontmatter {
          modern_energy {
            title
            text
          }
        }
      }
      mapBG: file(relativePath: { eq: "hero-bg-pattern.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { title, text } = data.markdownRemark.frontmatter.modern_energy;

  return (
    <div className="bg-black mt-20">
      <BackgroundImage
        className="bg-black bg-cover"
        fluid={data.mapBG.childImageSharp.fluid}
      >
        <div className="container flex flex-col items-center justify-center text-center space-y-10 py-20 lg:py-36 w-full px-5 lg:px-10 lg:max-w-none">
          <h1 className="max-w-2xl">{title}</h1>
          <h5 className="max-w-3xl text-white font-normal">{text}</h5>
          <Button
            buttonText="See How We’re Evolving"
            buttonLink="/updates"
            secondary={false}
          />
        </div>
      </BackgroundImage>
    </div>
  );
};

export default ModernEnergy;
