import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import DataInfo from "./data-info"

const DataProvider = () => {
    const data = useStaticQuery(graphql`
      query {
        markdownRemark(frontmatter: { name: { eq: "overview_content" } }) {
          id
          frontmatter {
            features {
              title
              cards {
                card_1 {
                  text
                  title
                }
                card_2 {
                  text
                  title
                }
                card_3 {
                  text
                  title
                }
              }
            }
          }
        }
      }
    `);

    const { title, cards } = data.markdownRemark.frontmatter.features
    const { card_1, card_2, card_3 } = cards

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col space-y-10 container max-w-container py-24 items-center">
                <h1 className="text-black mx-5 sm:mx-10 xl:mx-0 sm:mb-5">{title}</h1>
                <div className="flex flex-col space-y-5 mx-5 sm:mx-10 lg:mx-5 xl:mx-0 lg:space-y-0 lg:flex-row lg:space-x-5">
                    <DataInfo heading={card_1.title} desc={card_1.text} />
                    <DataInfo heading={card_2.title} desc={card_2.text} />
                    <DataInfo heading={card_3.title} desc={card_3.text} />
                </div>
                
                <div className="w-full shadow-infobox hidden md:block">
                    <StaticImage 
                        src="../images/petroninja-map-1200.png" 
                        alt="Data Provider Graphic"
                        layout="fullWidth"
                    />
                </div>
                
                <div className="w-full shadow-infobox block md:hidden">
                    <StaticImage 
                        src="../images/petroninja-map-mobile-768.png" 
                        alt="Data Provider Graphic"
                        layout="fullWidth"
                    />
                </div>

            </div> 
        </div>
    )
}

export default DataProvider;