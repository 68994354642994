import * as React from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
import HeroHome from "../components/hero-home";
import PublicData from "../components/public-data";
import DataProvider from "../components/data-provider";
import MapBG from "../components/map-bg";
import Features from "../components/features";
import ModernEnergy from "../components/modern-energy";
import Logotypes from "../components/logotypes";

const IndexPage = () => {
  return (
    <>
      <SEO />
      <Layout>
        <main>
          <HeroHome />
          <PublicData />
          <MapBG>
            <DataProvider />
            <Features />
            <ModernEnergy />
            <Logotypes
              heading="Companies Building on Petro Ninja"
              last={false}
              shadow={false}
            />
          </MapBG>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
