import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"

import Button from "./button"

const HeroHome = () => {
    const data = useStaticQuery(graphql`
      query {
        markdownRemark(frontmatter: { name: { eq: "overview_content" } }) {
          frontmatter {
            banner {
              title
              text
            }
          }
        }
        heroBG: file(relativePath: { eq: "herohome-bg-pattern.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `);
    
    const { title, text } = data.markdownRemark.frontmatter.banner
    
    return (
        <BackgroundImage className="bg-white bg-cover bg-center relative lg:py-68 flex items-center justify-center min-h-hero-container-mobile lg:min-h-hero-container" fluid={data.heroBG.childImageSharp.fluid}>
            <div className="flex flex-col lg:flex-row relative w-full h-full items-center min-h-hero-container-mobile lg:min-h-hero-container">
                <div className="flex items-center lg:space-x-5 xl:space-x-20 container max-w-container h-full pl-5 sm:pl-10 lg:pl-0">
                    <div className="lg:max-w-2xl xl:max-w-xl lg:flex-shrink-0 flex-col space-y-10 pt-10 lg:pt-0 lg:px-10 xl:px-0">
                        <h1 className="text-black">{title}</h1>
                        <h3 className="text-gray">{text}</h3>
                        <div className="flex space-x-10">
                            <Button buttonText="Request a demo" buttonLink="/contact" secondary={false} />
                            <Button buttonText="Partners" buttonLink="/partners" secondary={true} />
                        </div>
                    </div>
                </div>
                <div className="relative mt-5 lg:mt-0 lg:absolute lg:right-0 lg:max-h-full lg:max-w-sm xl:max-w-xl 2xl:max-w-3xl 3xl:max-w-4xl">
                    <StaticImage 
                        src="../images/main-header-graphic@2x.png" 
                        alt="Header graphics"
                        width={900}
                        height={900} 
                    />
                </div>
            </div>
        </BackgroundImage>
    )
}

export default HeroHome;