import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import ParagraphWithImage from "./paragraph-with-image";
import SingleFeature from "./single-feature";

import { Link } from "gatsby";
import IdeaIcon from "../images/idea.svg";

const AccordionItem = ({ isCollapsed, p, linkTo, linkText }) => {
  return (
    <>
      <div
        className={`accordion-item bg-section p-7 pb-10 ${
          isCollapsed ? "collapsed" : "expanded"
        }`}
        aria-expanded={isCollapsed}
      >
        <p>{p}</p>

        <div className="flex items-start flex-col space-y-5 sm:flex-row sm:space-y-0 sm:space-x-5 mt-7">
          <img width="23.31" height="25.88" src={IdeaIcon} alt="Tip" />
          <Link to={linkTo} className="underline">
            {linkText}
          </Link>
        </div>
      </div>
    </>
  );
};

const Accordion = ({ defaultIndex, onItemClick, children }) => {
  const [bindIndex, setBindIndex] = useState(defaultIndex);

  const changeItem = (itemIndex) => {
    if (typeof onItemClick === "function") onItemClick(itemIndex);
    if (itemIndex !== bindIndex) setBindIndex(itemIndex);
  };
  return (
    <div>
      <div className="flex flex-col space-y-1 sm:flex-row sm:space-y-0 sm:space-x-1 text-feature-heading">
        {children.map(({ props }) => (
          <button
            key={props.label}
            className={`flex-1 py-3 ${
              bindIndex === props.index ? "active-section" : "inactive-section"
            }`}
            onClick={() => changeItem(props.index)}
          >
            {props.label}
          </button>
        ))}
      </div>
      {children.map(({ props }) => (
        <AccordionItem
          isCollapsed={bindIndex !== props.index}
          label={props.label}
          children={props.children}
          handleClick={() => changeItem(props.index)}
          p={props.p}
          linkTo={props.linkTo}
          linkText={props.linkText}
          key={props.label}
        />
      ))}
    </div>
  );
};

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      collaborate: markdownRemark(
        frontmatter: { name: { eq: "overview_content" } }
      ) {
        frontmatter {
          collaborate {
            title
            text
            bullets {
              bullet_1 {
                text
                title
              }
              bullet_2 {
                text
                title
              }
              bullet_3 {
                text
                title
              }
              bullet_4 {
                text
                title
              }
            }
          }
        }
      }
      connect: markdownRemark(
        frontmatter: { name: { eq: "overview_content" } }
      ) {
        frontmatter {
          connect {
            title
            text
            tip
            tabs {
              tab_1 {
                link_text
                text
                title
              }
              tab_2 {
                link_text
                text
                title
              }
            }
          }
        }
      }
      efficientWorkflows: markdownRemark(
        frontmatter: { name: { eq: "overview_content" } }
      ) {
        frontmatter {
          efficient_workflows {
            title
            text
            tip
            bullets {
              bullet_1 {
                text
                title
                link_text
              }
              bullet_2 {
                text
                title
                link_text
              }
              bullet_3 {
                link_text
                text
                title
              }
            }
          }
        }
      }
    }
  `);

  const {
    title: collaborateTitle,
    text: collaborateText,
    bullets: collaborateBullets,
  } = data.collaborate.frontmatter.collaborate;
  const {
    title: connectTitle,
    text: connectText,
    tabs,
    tip: connectTip,
  } = data.connect.frontmatter.connect;
  const {
    title: efficientWorkflowsTitle,
    text: efficientWorkflowsText,
    bullets: efficientWorkflowsBullets,
    tip: efficientWorkflowsTip,
  } = data.efficientWorkflows.frontmatter.efficient_workflows;

  const {
    bullet_1: bullet_1_collaborate,
    bullet_2: bullet_2_collaborate,
    bullet_3: bullet_3_collaborate,
    bullet_4: bullet_4_collaborate,
  } = collaborateBullets;

  const { tab_1, tab_2 } = tabs;

  const {
    bullet_1: bullet_1_efficient_workflows,
    bullet_2: bullet_2_efficient_workflows,
    bullet_3: bullet_3_efficient_workflows,
    bullet_4: bullet_4_efficient_workflows,
  } = efficientWorkflowsBullets;

  return (
    <div className="flex flex-col space-y-10 md:space-y-20 mx-5 sm:mx-10">
      <ParagraphWithImage
        featureTitle="Collaborate"
        heading={collaborateTitle}
        desc={collaborateText}
        section="home_first"
        additionalBottomSection={true}
      >
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10">
          <SingleFeature
            heading={bullet_1_collaborate.title}
            desc={bullet_1_collaborate.text}
          />
          <SingleFeature
            heading={bullet_2_collaborate.title}
            desc={bullet_2_collaborate.text}
          />
          <SingleFeature
            heading={bullet_3_collaborate.title}
            desc={bullet_3_collaborate.text}
          />
          <SingleFeature
            heading={bullet_4_collaborate.title}
            desc={bullet_4_collaborate.text}
          />
        </div>
      </ParagraphWithImage>

      <ParagraphWithImage
        featureTitle="Connect"
        heading={connectTitle}
        desc={connectText}
        section="home_second"
        italic={connectTip}
        additionalBottomSection={false}
      >
        <div className="flex flex-col text-black pt-5">
          <Accordion defaultIndex="1">
            <AccordionItem
              key="1"
              label={tab_1.title}
              index="1"
              p={tab_1.text}
              linkTo="/contact"
              linkText={tab_1.link_text}
            />
            <AccordionItem
              key="2"
              label={tab_2.title}
              index="2"
              p={tab_2.text}
              linkTo="/contact"
              linkText={tab_2.link_text}
            />
          </Accordion>
        </div>
      </ParagraphWithImage>

      <ParagraphWithImage
        featureTitle="Efficient Workflows"
        heading={efficientWorkflowsTitle}
        desc={efficientWorkflowsText}
        section="home_third"
        italic={efficientWorkflowsTip}
        additionalBottomSection={true}
      >
        <div className="w-full flex flex-col space-y-10 sm:space-y-0 sm:flex-row sm:space-x-10">
          <SingleFeature
            heading={bullet_1_efficient_workflows.title}
            desc={bullet_1_efficient_workflows.text}
            link={true}
            linkText={bullet_1_efficient_workflows.link_text}
            linkTo="/contact"
          />
          <SingleFeature
            heading={bullet_2_efficient_workflows.title}
            desc={bullet_2_efficient_workflows.text}
            link={true}
            linkText={bullet_2_efficient_workflows.link_text}
            linkTo="https://help.petroninja.com/integrations/ssd"
          />
          <SingleFeature
            heading={bullet_3_efficient_workflows.title}
            desc={bullet_3_efficient_workflows.text}
            link={true}
            linkText={bullet_3_efficient_workflows.link_text}
            linkTo="/contact"
          />
        </div>
      </ParagraphWithImage>
    </div>
  );
};

export default Features;
