import * as React from "react"

const DataInfo = ({heading, desc}) => {
  return (
      <div className="flex-1 bg-white border-t-10 border-red shadow-section px-5 sm:px-7 py-10 hover:border-dark-red">
          <h4 className="text-data-info-heading text-black h-auto lg:h-20">{heading}</h4>
          <p>{desc}</p>
      </div>
  )
}

export default DataInfo
